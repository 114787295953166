export const categories = [
  "Newyork",
  "Lyon",
  "Freeport",
  "Cambodia",
  "Florida",
  "Greece",
  "Italy",
  "England",
  "China",
  "Vietnam",
  "Family",
  "Scotland",
  "Sweden",
  "Thailand",
]
