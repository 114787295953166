import React from "react"
import Link from "gatsby-link"
import { categories } from "../utils/category"
import * as styles from "./navBar.module.scss"

export const Nav = () => {
  const activeStyles = {
    colour: "red",
  }

  return (
    <div className={styles.navMain}>
      <>
        <h1 className={styles.navHeaderSpots}> SPOTS </h1>
        <ul className={styles.unorderedList}>
          <li className={styles.list}>
            {categories.map((category, i) => {
              return (
                <Link
                  className={styles.navTag}
                  to={`/tags/${category.toLowerCase()}`}
                  activeStyle={activeStyles}
                  key={i}
                >
                  <h2 className={styles.navItems}> {category} </h2>
                </Link>
              )
            })}
          </li>
        </ul>
      </>
    </div>
  )
}
