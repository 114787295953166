import * as React from "react"
import Link from "gatsby-link"
import * as styles from "./burgerMenu.module.scss"
import { categories } from "../utils/category"

function BurgerMenu({ close }) {
  return (
    <div className={styles.menuLayout}>
      <ul>
        <li>
          {categories.map(category => {
            return (
              <Link
                onClick={close}
                className={styles.menuItemsLink}
                to={`/tags/${category.toLowerCase()}`}
              >
                <h2 className={styles.menuItems}> {category} </h2>
              </Link>
            )
          })}
        </li>
      </ul>
    </div>
  )
}

export default BurgerMenu
