import * as React from "react"
import { useState } from "react"
import { Link } from "gatsby"
import { Fade as Hamburger } from "hamburger-react"
import Popup from "reactjs-popup"
import "reactjs-popup/dist/index.css"
import * as styles from "./headerStyles.module.scss"
import * as burgerStyles from "./burger.module.scss"
import BurgerMenu from "./burgerMenu"

function Header() {
  const [isOpen, setOpen] = useState(false)

  const contentStyle = {
    // background: "#1E1F21",
    background: "rgba(255,255,255,0)",
    width: "100%",
    border: "none",
  }

  return (
    <div className={styles.blogHeader} id={"backToHeader"}>
      <Link className={styles.kurtisLink} to={"/"}>
        <h2 className={styles.kurtisText}>Kurtis Angell</h2>
        <h2 className={styles.kurtisMobile}>Kurtis</h2>
      </Link>

      <div className={styles.hamburgerReact}>
        <Hamburger
          size={25}
          duration={0.1}
          direction={"right"}
          color={"grey"}
          toggled={isOpen}
          toggle={setOpen}
        />
      </div>

      <div>
        <Popup
          modal
          closeOnDocumentClick={false}
          contentStyle={contentStyle}
          open={isOpen}
          close={isOpen}
        >
          {close => (
            <BurgerMenu className={burgerStyles.burgerMenu} close={close} />
          )}
        </Popup>
      </div>
    </div>
  )
}

export default Header
